import {
  DDQuestion,
  DocumentDrafterQuestionnaire,
  QuestionnaireWrapper, RequestForOfferQuestionnaireValidator,
  Tender, OfferQuestionnaireValidatorFunctions
} from '@dims/components';
import { DateTime } from 'luxon';
import deliveryAgreementIds from '@/models/DeliveryAgreementIds';

export default class RequestQuestionnaireValidator implements RequestForOfferQuestionnaireValidator {
  private readonly questionnaire;
  private wrapInvalidWeightedValidationMessage = true;
  private invalidWeightingValidationMessage = '';
  private readonly offerQuestionnaireValidatorFunctions;

  constructor(readonly tender: Tender, questionnaire: DocumentDrafterQuestionnaire) {
    this.offerQuestionnaireValidatorFunctions = new OfferQuestionnaireValidatorFunctions();
    this.questionnaire = new QuestionnaireWrapper(
      this.tender.agreementConfiguration.questionnaires.requestForOffer,
      questionnaire,
    );
  }
  get incomplete() {
    return !this.questionnaire.content.complete;
  }

  get numericValidators() {
    if (this.tender.awardForm === 'DIS') {
      return {
        ['default']: { min: 0.1, max: undefined },
      };
    }
    else {
      return {
        ['default']: { min: 1, max: undefined },
      };
    }
  }

  minDate(question: DDQuestion): string | undefined {
    if (question.questionId === deliveryAgreementIds.idDeliveryDate) {
      return this.tender.deadlineForTender ?? undefined;
    }
    if (question.dataFieldType === 'Date') {
      return DateTime.local().toISODate();
    }
    return undefined;
  }

  get weightQuestions() {
    const weightQuestionIds = [
      deliveryAgreementIds.idFunctionality,
      deliveryAgreementIds.idTransition,
      deliveryAgreementIds.idCollaboration,
      deliveryAgreementIds.idSecurity,
      deliveryAgreementIds.idGreenInitiatives,
    ];

    return this.questionnaire.content.questions.filter((q) => weightQuestionIds.includes(q.questionId));
  }

  get isSubcriteriaValid() {
    return this.sumOfSubCriteria === 10.0;
  }

  get sumOfSubCriteria() {
    const sum = (arr: number[]) => arr.reduce((previousq, q) => previousq + q, 0);
    const values = this.weightQuestions.map((q) => (q.answers?.[0] && q.answers[0].value !== '' ? parseFloat(q.answers[0].value.replace(',', '.')) : 0));
    return sum(values);
  }

  get subCriteriaValidation() {
    if (this.isSubcriteriaValid) {
      return null;
    }
    const sum = this.sumOfSubCriteria;
    return { message: `Summen af delkriterierne skal være 10.0 point (Summen er ${sum})` };
  }

  get isQualityWeightingValid() {
    console.log('Abe 3');

    this.wrapInvalidWeightedValidationMessage = true;

    const sortedWeightQuestions = this.offerQuestionnaireValidatorFunctions.sortOfferQuestions(this.weightQuestions);

    // Weighting no duplicates
    const seenAnswers = new Set<number>();
    for (const question of sortedWeightQuestions) {
      const questionAnswer = this.offerQuestionnaireValidatorFunctions.getQuestionAnswer(question);

      if (seenAnswers.has(questionAnswer)) {
        this.wrapInvalidWeightedValidationMessage = false;
        this.invalidWeightingValidationMessage = 'Kriterier må ikke vægtes ens';
        return false; // Duplicate found
      }

      seenAnswers.add(questionAnswer);
    }

    // Weighting order
    if (sortedWeightQuestions.length > 0 && sortedWeightQuestions[0]?.questionId !== deliveryAgreementIds.idFunctionality) {
      this.invalidWeightingValidationMessage = 'funktionalitet og brugervenlighed skal vægtes højst';
      return false;
    }

    if (sortedWeightQuestions.length > 1 && sortedWeightQuestions[1]?.questionId !== deliveryAgreementIds.idTransition) {
      this.invalidWeightingValidationMessage = 'transition - implementering og ophør af servicen skal vægtes næsthøjest';
      return false;
    }

    if (sortedWeightQuestions.length > 2) {
      // Check if optional questions are in correct order
      let index = 2;
      if (sortedWeightQuestions[index]?.questionId === deliveryAgreementIds.idCollaboration) {
        index += 1;
      } else if (this.offerQuestionnaireValidatorFunctions.questionIdAnswerSet(deliveryAgreementIds.idCollaboration, sortedWeightQuestions)) {
        this.invalidWeightingValidationMessage = 'organisation of samarbejde skal vægtes tredjehøjst, hvis valgt';
        return false;
      }

      if (sortedWeightQuestions[index]?.questionId === deliveryAgreementIds.idSecurity) {
        index += 1;
      } else if (this.offerQuestionnaireValidatorFunctions.questionIdAnswerSet(deliveryAgreementIds.idSecurity, sortedWeightQuestions)) {
        this.invalidWeightingValidationMessage = 'it-sikkerhed skal vægtes fjerdehøjst, hvis valgt';
        return false;
      }

      if (sortedWeightQuestions[index]?.questionId === deliveryAgreementIds.idGreenInitiatives) {
        index += 1;
      } else if (this.offerQuestionnaireValidatorFunctions.questionIdAnswerSet(deliveryAgreementIds.idGreenInitiatives, sortedWeightQuestions)) {
        this.invalidWeightingValidationMessage = 'grønne tiltag" skal vægtes lavt, hvis valgt';
        return false;
      }
    }

    // See #24128. Only assign to "this.invalidWeightingValidationMessage" once, since
    // this is a watched value. And the Vue framework gets confused if the value
    // changes many times in the same function, and Vue will stop watching it.
    this.invalidWeightingValidationMessage = '';
    return true;
  }

  get qualityWeightingValidation() {
    if (this.isQualityWeightingValid) {
      return null;
    }
    if (this.wrapInvalidWeightedValidationMessage) {
      return { message: `Delkriterier skal vægtes i korrekt rækkefølge (${this.invalidWeightingValidationMessage}).` };
    }

    return { message: this.invalidWeightingValidationMessage };
  }
}

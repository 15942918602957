<template>
  <div>
    <LoadingSpinner :visible="isLoading" loadingMessage="Henter tilbud" />
    <div v-if="!isLoading">
      <v-card v-if="tender" class="pa-3" color="#fff9ef">
        <div>
          <v-card-text>
            <div class="pdf-button">
              <v-btn
                variant="text"
                @click="downloadOffer()"
                class="float-right"
              >Download tilbud<v-icon end>mdi-download</v-icon></v-btn>
              <LoadingSpinner
                :visible="isLoading"
                loadingMessage="Danner PDF..."
              />
            </div>
            <div>{{ tender.user.companyName }}</div>
            <div>{{ tender.description }}</div>
            <div>{{ tender.friendlyId }}</div>
            <h1 class="font-weight-bold">Tilbud</h1>
            <h3 class="mt-2">
              <p
                v-if="offer.sender.companyName && offerCvr"
                class="mb-1"
              >
                {{
                  `${offer.sender.companyName}, CVR. ${offerCvr}`
                }}
              </p>
              <p
                v-if="offerPublishedDate && offer.sender.fullName"
                class="mb-1"
              >
                {{
                  `Tilbud indsendt d. ${offerPublishedDate} af ${offer.sender.fullName}`
                }}
              </p>
              <br />
            </h3>
            <TcoCalculationSummary
              :questions="tcoCalculationQuestions"
              :tcoCalculationResult
              cardStyle="#fff9ef"
              tableStyle="transparent"
            />
            <br /><br />
          </v-card-text>
          <v-card-text>
            <div
              v-for="tab in supplierQuestions"
              :key="tab.questionId"
            >
              <h2>{{ tab.title }}</h2>
              <br />
              <ReadOnlyTemplateQuestion
                :section="tab"
                :question="tab"
                :questionnaire
                :level="1"
                :panelState
                @panelsUpdate="panelsUpdate"
                :tender
              />
            </div>
          </v-card-text>
          <v-card-text>
            <div
              v-for="(attachment, i) in offer.artifacts"
              :key="i"
            >
              <h2>{{ attachment.templateArtifactKey === "espd" ? "ESPD dokument" : "Tilbudsbrev" }}</h2>
              <br />
              <div class="px-10 py-15" style="background-color: white;">
                <v-text-field
                  class="file-download-box pb-1 px-4"
                  readonly
                  hideDetails
                  density="compact"
                  variant="plain"
                >
                  {{ attachment.displayName }}
                  <template #prepend>
                    <v-avatar color="canvas-darken-3">
                      <v-icon icon="mdi-file-outline" />
                    </v-avatar>
                  </template>
                  <template #append>
                    <v-btn
                      @click="download(attachment)"
                      icon="mdi-download-outline"
                    />
                  </template>
                </v-text-field>
              </div>
            </div>
          </v-card-text>
        </div>
      </v-card>
    </div>
    <LoadingSpinner
      :loadingMessage
      :visible="isDownloading"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from 'vue';
import {
  LoadingSpinner,
  Offer,
  Tender,
  dateUtils,
  useStore,
  Questionnaire,
  TemplateQuestionPanelState,
  QuestionTypesEnum,
  Question,
  downloadFrom,
  downloadDocument,
  OfferArtifact,
  useBackend,
} from '@dims/components';
import TcoCalculationSummary from '../OfferDetails/TcoCalculation/TcoCalculationSummary.vue';
import { setSupplierPriceTab } from '@/services/questionnaireTabConfig';

const { tender, offer } = defineProps<{ tender: Tender, offer: Offer }>();
const store = useStore();
const backend = useBackend();

const offerCvr = ref('');
const priceSection = ref(0);

const questionnaire = ref<Questionnaire | null>(null);

onMounted(async () => {
  await fetchQuestionnaire();
  priceSection.value = setSupplierPriceTab(questionnaire.value ?? null);
  offerCvr.value = await backend.supplierService.fetchCvrFromOrg(tender.agreementName, offer.sender.organizationNumber) ?? '';
});

const isLoading = ref(false);
const isDownloading = ref(false);
const loadingMessage = ref('Vent venligst');
const panelState = ref<TemplateQuestionPanelState>(TemplateQuestionPanelState.INIT);

async function downloadOffer() {
  if (tender.id) {
    isLoading.value = true;
    try {
      const archiveUrl = await store.backend.exportDataService.exportOffer(
        offer.id,
      );
      downloadFrom(archiveUrl);
    } catch (e) {
      console.error(e);
      store.setSnackbarText('Kan ikke downloade materiale.');
    } finally {
      isLoading.value = false;
    }
  }
}

async function fetchQuestionnaire() {
  isLoading.value = true;
  try {
    const updatedQuestionnaire = await store.backend.questionnaireService
      .getReadonlyQuestionnaire(
        tender.id,
        'offer',
        offer.id,
      );
    questionnaire.value = updatedQuestionnaire;
  } catch (e) {
    console.error(e);
    store.setSnackbarText('Error');
  }
  isLoading.value = false;
}

const offerPublishedDate = computed((): string => {
  const date = offer.publishedDate;
  return dateUtils.shortDateTimeFormat(date);
});

function panelsUpdate() {
  panelState.value = TemplateQuestionPanelState.CHANGED;
}

// Find suppplier filled questions
function* getSuppplierQuestions(questions: Question[]): IterableIterator<Question> {
  for (const question of questions) {
    if ([QuestionTypesEnum.FILES, QuestionTypesEnum.NUMBER, QuestionTypesEnum.OPTIONS, QuestionTypesEnum.PRICEPOINTSET]
      .includes(question.questionType as QuestionTypesEnum) && question.enabled) {
      yield question;
    } else if (question.questions) {
      yield* getSuppplierQuestions(question.questions);
    }
  }
}

const supplierQuestions = computed((): Question[] => Array.from(getSuppplierQuestions(questionnaire.value?.questions ?? [])));

// Questions used for offer status overview
// Exclude the first question (general information) from the summary
const tcoCalculationQuestions = computed(() => questionnaire.value?.questions[priceSection.value]?.questions?.filter((x) => x.enabled && !x.key?.includes('tcoExcludedQuestion')) ?? []);

const tcoCalculationResult = computed(() => questionnaire.value?.tcoCalculationResult);

async function download(attachment: OfferArtifact) {
  try {
    const doc = await store.backend.documentDrafterService.downloadDocument(
      tender.id,
      'offer',
      attachment.templateArtifactKey,
      offer.id,
    );
    downloadDocument(doc);
  } catch (error) {
    console.error(error);
  }
}

</script>

<template>
  <EvaluationOfferListBaseV2
    v-if="features.EvaluationV2"
  >
    <template #additional-columns></template>
    <template #tco>
      <TcoDocumentationTrigger btnColor="white" />
    </template>
    <template #bpq="{ offer, conditionalOffers, index }">
      <BpqDocumentationDialog
        :offer="offer"
        :offerEvaluation="evaluationStore.getOfferEvaluation(offer)"
        :index="index"
        :isOfferConditional="conditionalOffers.includes(offer)"
      />
    </template>
  </EvaluationOfferListBaseV2>
  <EvaluationOfferListBase
    v-else
    :tender="evaluationStore.tender"
    :offers="evaluationStore.offers"
    :offersEvaluations="evaluationStore.offersEvaluations"
    :additionalHeaders
    :isLoadingOffers="evaluationStore.isLoadingOffers"
    contractSumTooltip="Den samlet tilbudte pris inkl. optioner"
    @fetchOffers="evaluationStore.fetchAndSortOffers(calculator)"
    @updateTab="emit('updateTab', $event)"
  >
    <template #additional-columns></template>
    <template #tco="{ btnColor }">
      <TcoDocumentationTrigger
        :btnColor
      />
    </template>
    <template #bpq="{ offer, conditionalOffers, index }">
      <BpqDocumentationDialog
        :offer
        :offerEvaluation="evaluationStore.getOfferEvaluation(offer)"
        :index
        :isOfferConditional="conditionalOffers.includes(offer)"
      />
    </template>
  </EvaluationOfferListBase>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { EvaluationOfferListBase, EvaluationOfferListBaseV2, useEvaluationStore, useFeatures } from '@dims/components';
import BpqDocumentationDialog from './BpqDocumentationDialog.vue';
import TcoDocumentationTrigger from './TcoDocumentationTrigger.vue';
import { getCalculator } from '@/services';

const emit = defineEmits<{
  updateTab: [string],
}>();

const calculator = getCalculator();

const evaluationStore = useEvaluationStore();

const features = useFeatures();

const additionalHeaders = ref([]);

</script>
